.slick-dots {
  .slick-active {
    position: relative;
    button::after {
      content: '';
      position: absolute;
      top: 40%;
      left: 45%;
      width: 3px;
      height: 3px;
      border: 2px solid rgb(0, 0, 0);
      border-radius: 100%;
      scale: 1.5;
    }
  }
}
