@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
}

a,
a:hover {
  text-decoration: none;
}

p {
  font-family: 'Roboto', sans-serif;
}

@font-face {
  font-family: Roboto Slab, sans-serif;
  src: url(assets/fonts/RobotoSlab-VariableFont_wght.ttf);
}

html,
body {
  scroll-behavior: smooth;
}

.media__hero--foure {
  background: url('./assets/image/aboute.webp') no-repeat center/cover;
}

.media__hero--secondary {
  background: url('./assets/image/photo_2023-03-02_16-33-56.webp') no-repeat
    center/cover;
}

.media__hero--three {
  background: url('./assets/image/photo_2023-03-02_16-34-01.webp') no-repeat
    center/cover;
}

.media__hero--first {
  background: url('./assets/image/photo_2023-03-02_16-34-13.webp') no-repeat
    center/cover;
}

.video-bg {
  top: 0 !important;
  max-width: 1900px;
  width: 100%;
  position: absolute;
  min-height: 90vh;
}

@media screen and (max-width: 992px) {
  .media__hero {
    margin-top: 70px;
  }
}

.text__vulkan {
  animation: card 6s ease-in infinite;
}

@keyframes card {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  18% {
    opacity: 0;
  }
  35% {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}


.talas {
  .talas2 {
  background: red;

  }
}
