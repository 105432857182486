.pulse {
  transition: 0.5s;
}

.pulse::before,
.pulse::after {
  content: '';
  position: absolute;
  border: 2px solid #ffffff;
  left: -20px;
  right: -20px;
  top: -20px;
  bottom: -20px;
  border-radius: 50%;
  animation: pulse 1.8s linear infinite;
  opacity: 0;
}
.pulse::after {
  animation-delay: 0.5s;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
