$transitionTime: '1' '.5', '2' '.7', '3' '1', '4' '1.2', '5' '1.5';

.burger-button {
  background: transparent;
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;

  @media screen and (min-width: 992px) {
    display: none;
  }

  &-top,
  &-center,
  &-bottom {
    background: #7e8085;
    transition: all 0.5s ease-in-out;
  }

  &.active {
    .burger-button-top {
      transform: rotate(45deg) translate(3px, 10px);
      width: 30px;
      z-index: 50;
    }
    .burger-button-center {
      transform: translate(100%);
      opacity: 0;
    }
    .burger-button-bottom {
      transform: rotate(-45deg) translate(0px, -9px);
      width: 30px;
      z-index: 50;
    }
  }
}

.mobile-menu {
  transform: translateX(150vw);
  transition: all 0.55s;
  opacity: 0;

  &.transform {
    transform: translateX(0);
    opacity: 1;

    @media screen and (min-width: 992px) {
      display: none;
    }
  }

  &-link {
    display: flex;
    justify-content: end;
    font-size: 24px;
    margin-bottom: 46px;
    font-weight: 600;

    &.active {
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.15);
      padding: 20px;
    }
  }
}

.item {
  &:hover {
    color: #f5a700;
    transition: 0.2s;
    &::after {
      transition: 0.4s all;
      width: 100%;
    }
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    height: 2px;
    background-color: #f5a700;
  }
}

.modal {
  position: absolute;
  top: 72px;
  bottom: 0;
  left: 0;
  right: 0;
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  display: none;
  transition: 0.2s;
  width: 255px;

  &.active {
    animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    display: block;
  }
}

.dropLang {
  position: absolute;
  top: 72px;
  bottom: 0;
  left: 0;
  right: 0;
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  display: none;
  transition: 0.2s;
  width: 300px;
  transform: 5s;

  &.active {
    animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    display: block;
    transform: 5s;
  }
}

#header {
  .black_nav_menu {
    animation: card__nav 0.5s ease-in;
  }
  .black_menu_none {
    animation: menu__nav 0.5s ease-in;
  }

  @keyframes card__nav {
    from {
      opacity: 0;
      transform: translateY(-70px);
    }
    50% {
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes menu__nav {
    from {
      transform: translateY(70px);
    }
    to {
      transform: translateY(0);
    }
  }
}

@media screen and (max-width: 992px) {
  .header__head {
    width: 100%;
    background: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    border-bottom: 1px solid #bcbcbc;
  }
}

.list__menu {
  transform: translateY(0);
  height: 100%;
  transition: 0.5s ease;
}

.list__menu__close {
  transform: translateY(-300px);
  height: 0;
  transition: 0.3s ease;
}


.map {
	@media (max-width: 500px) {
	}

	iframe {
		width: 100%;
		height: 403.5px;
		border-radius: 10px;
		overflow: hidden; // Убирает скролл
		border: none; //

		@media (max-width: 500px) {
			height: 253.5px;
			border-radius: 0px;
		}
	}
}
