.icon__up {
	animation: menu__item 0.5s ease-in;
}

.icon__down {
	transform: translateY(90px);
	transition: 0.3s all;
}

@keyframes menu__item {
	from {
		opacity: 0;
		transform: translateY(60px);
	}
	50% {
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}
