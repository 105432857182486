.parallax {
    /* The image used */
    background-image: url('../../../assets/img/imagePar.webp');

    /* Full height */
    height: 100%;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
